import React from "react";

export function AccordionItem({ title, activeAccordion, setActiveAccordion, id, children , iconName }) {
  const handleAccordionClick = () => {
    setActiveAccordion((prev) => (prev === id ? "" : id));
  };
  return (
    <div
      style={{
        cursor: "pointer",
        paddingBottom: "10px",
      }}
    >
      <div
        style={{
          cursor: "pointer",
          background: "white",
          padding: "10px",
          border: "1px solid #ccc",
          display:"flex",
          justifyContent:"space-between",
        alignItems:"center"
        }}
        onClick={handleAccordionClick}
      >
        <div>

       <s-icon class="pad-right-xs hydrated" name={iconName}></s-icon> {title}
        </div>
      <div style={{display:"flex",justifyContent:"right"}}>
      <s-icon class="pad-right-xs hydrated" name="chevron-right"></s-icon>
      </div>
      </div>
      {activeAccordion === id && (
        <div style={{ padding: "10px", border: "1px solid #ccc" }}>
          {children}
        </div>
        
      )}
                
    </div>
  );
}