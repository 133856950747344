import React, { useCallback, useEffect, useState } from "react";
import { Col, Row, Loader } from "@avalara/skylab-react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { DataGrid, gridHeader } from "../../shared/grid/index.js";
import { Pagination } from "@avalara/skylab-react";
import { Link } from "react-router-dom";
import {
  fetchTenantsByOrgIdAPI,
  selectPage,
  setPageToNull,
  selectIsTenantsLoading,
} from "../../app/organizationSlice.js";

import { fetchTenantsByIdAPI } from "../../app/userSlice.js";

export function Tenants(props) {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const loading = useSelector(selectIsTenantsLoading);
  const page = useSelector(selectPage, shallowEqual);

  useEffect(() => {
    if (props?.id) {
      if (props?.from === "org")
        dispatch(fetchTenantsByOrgIdAPI(props?.id, 20, null, true));
      else {
        if (props?.from === "usr")
          dispatch(fetchTenantsByIdAPI(props?.id, 20, null, true));
      }
    }
    return () => {
      dispatch(setPageToNull());
    };
  }, [dispatch, props.id]);
  const getRowKey = useCallback((row) => {
    return row.id;
  }, []);

  const pageData = page?.data; //tenantData.items; //
  const paginate = page?.paginate;
  let emailsTable = [];
  let columns;
  if (pageData && page?.status && page?.paginate?.totalRecords) {
    columns = [
      gridHeader(
        "Tenant Id",
        "id",
        (value, row) => (
          <Link to={`/tenantDetails/${row.id}`} state={{ myObj: row }}>
            {value}
          </Link>
        ),
        null,
        "",
        null,
        null,
        null,
        "id"
      ),
      gridHeader("Display Name", "displayName", (value) => value, null, ""),
      gridHeader(
        "Org Id",
        "org",
        (value, row) => row?.organization?.identifier,
        null,
        ""
      ),
      gridHeader(
        "Organization",
        "org Name",
        (value, row) => row.organization.displayName,
        null,
        ""
      ),
      gridHeader(
        "Tags",
        "tags",
        (value, row) =>
          row?.tags?.length > 1 ? (
            <div>
              <s-table-container class="no-border name-table">
                <table className="row-height-sm borderless">
                  <tbody>
                    {Object.keys(row?.tags).map((v, idx) => {
                      emailsTable.push(
                        <tr key={row?.tags[idx]}>
                          <td className="pad-left-none">
                            {row?.tags[idx]?.name}
                          </td>
                        </tr>
                      );

                      const returnMap = emailsTable;
                      emailsTable = [];
                      return returnMap;
                    })}
                  </tbody>
                </table>
              </s-table-container>
            </div>
          ) : (
            row?.tags?.length > 0 &&
            row?.tags[0] && <span>{row?.tags[0].name}</span>
          ),
        null,
        "",
        null,
        null,
        null,
        null
      ),
    ];
  }

  let table = "";
  if (loading || submitting) {
    table = (
      <div className="flex dl-flex-fill-height dl-flex-center">
        <h3>Loading ...</h3>
        <Loader id="page-loader" class="medium" aria-live="polite" loading />
      </div>
    );
  } else if (pageData && page?.status && page?.paginate?.totalRecords) {
    table = (
      <s-table-container class="margin-top-xl th-with-filter">
        <DataGrid
          columns={columns}
          rows={pageData}
          inContainer
          getKey={getRowKey}
        />
      </s-table-container>
    );
  } else {
    table = (
      <React.Fragment>
        <div className="margin-top-sm">
          <div className="font-semibold font-normal text-md">
            No tenants are found
          </div>
        </div>
      </React.Fragment>
    );
  }

  const handlePaginate = async (e) => {
    if (
      e.detail.currentPage !== 0 &&
      paginate?.totalRecords > 0 &&
      e.detail.flag !== ""
    ) {
      setSubmitting(true);
      dispatch(
        fetchTenantsByOrgIdAPI(
          e.detail.rowsPerPage,
          e.detail.startIndex,
          false,
          e.detail
        )
      );
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Row>
        <Col>
          {table}
          {pageData &&
          page?.status &&
          page?.paginate?.totalRecords &&
          !loading ? (
            <Pagination
              className="margin-top-md"
              rowsPerPage={paginate.rowsPerPage}
              totalRecords={paginate.totalRecords}
              startIndex={paginate.startIndex}
              onSPaginate={(e) => {
                handlePaginate(e);
              }}
            />
          ) : null}
        </Col>
      </Row>
    </div>
  );
}
