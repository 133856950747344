import { createSlice } from "@reduxjs/toolkit";
import { buildURL } from "../shared/Utils";
import axios from "../axios";
import { getJwtToken } from "./sessionSlice";

export const organizationSlice = createSlice({
  name: "organization",
  initialState: {
    isloading: false,
    isTenantsLoading: false,
    isUsersLoading: false,
    isAppsLoading: false,
    isGroupsLoading: false,
    searchField: "",
    totalData: [],
    page: {
      data: [],
      paginate: {
        pageNumber: 0,
        startIndex: -1,
        endIndex: 0,
        rowsPerPage: 0,
        totalRecords: 0,
      },
    },
    sortColumn: ["id", true],
    noDataType: "",
  },

  reducers: {
    setLoading: (state, action) => {
      state.isloading = action.payload;
    },
    setTotalData: (state, action) => {
      state.totalData = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageToNull: (state) => {
      state.page = {
        data: [],
        paginate: {
          pageNumber: 0,
          startIndex: -1,
          endIndex: 0,
          rowsPerPage: 0,
          totalRecords: 0,
        },
      };
    },
    setNoDataType: (state, action) => {
      state.noDataType = action.payload;
    },
    setSortColumn: (state, action) => {
      state.sortColumn = action.payload;
    },
    setSearchField: (state, action) => {
      state.searchField = action.payload;
    },
    setTenantsLoading: (state, action) => {
      state.isTenantsLoading = action.payload;
    },
    setUsersLoading: (state, action) => {
      state.isUsersLoading = action.payload;
    },
    setAppsLoading: (state, action) => {
      state.isAppsLoading = action.payload;
    },
    setGroupsLoading: (state, action) => {
      state.isGroupsLoading = action.payload;
    },
  },
});

export const {
  setLoading,
  setPage,
  setTotalData,
  setPageToNull,
  setSortColumn,
  setNoDataType,
  setSearchField,
  setTenantsLoading,
  setUsersLoading,
  setAppsLoading,
  setGroupsLoading,
} = organizationSlice.actions;

export const selectPage = (state) => {
  return state.organization.page;
};

export const selectSortColumn = (state) => {
  return state.organization.sortColumn;
};

export const selectIsLoading = (state) => {
  return state.organization.isloading;
};

export const selectFormState = (state) => {
  return state.organization.formState;
};

export const selectNoData = (state) => {
  return state.organization.noDataType;
};
export const selectSearchField = (state) => {
  return state.organization.searchField;
};

export const selectIsTenantsLoading = (state) => {
  return state.organization.isTenantsLoading;
};

export const selectIsUsersLoading = (state) => {
  return state.organization.isUsersLoading;
};

export const selectIsAppsLoading = (state) => {
  return state.organization.isAppsLoading;
};

export const selectIsGroupsLoading = (state) => {
  return state.organization.isGroupsLoading;
};

export const fetchUsersAPI =
  (top, skip, onLanding, paginateDetails, selectedOption) =>
  async (dispatch, getState) => {
    const localState = getState().organization;
    const { isloading, searchField } = localState;
    if (isloading) {
      return;
    }
    dispatch(setLoading(true));
    //const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
    let filter = "";
    let apiURI = "";
    if (selectedOption === "userID") {
      apiURI = buildURL(
        `${process.env.REACT_APP_BASE_URL}/scim/v2/users/${searchField}`
      );
    } else {
      if (searchField && selectedOption) {
        switch (selectedOption) {
          case "emailID":
            filter = `emails eq '${searchField.trim()}'`;
            break;
          case "orgID":
            filter = `urn:avalara:schemas:extension:2.0:AvalaraUser.organization.identifier eq '${searchField.trim()}'`;
            break;
          case "subjectID":
            filter = `urn:avalara:schemas:extension:1.0:AvalaraUser.subjectId eq '${searchField.trim()}'`;
            break;
          case "userName":
            filter = `userName eq '${searchField.trim()}'`;
            break;
          default:filter = `userName eq '${searchField.trim()}'`;
            break;
        }
      }
      apiURI = buildURL(
        `${process.env.REACT_APP_BASE_URL}/scim/v2/users/`,
        filter,
        "",
        top,
        skip
        //sort
      );
    }

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${getJwtToken()}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(apiURI, requestOptions)
      .then((response) => response.json())
      .then((jsonresponse) => {
        const resulted = JSON.parse(JSON.stringify(jsonresponse));
        if (!resulted) {
          onLanding
            ? dispatch(setNoDataType("onLanding"))
            : dispatch(setNoDataType("onSearch"));
          dispatch(setPageToNull());
        } else {
          dispatch(setNoDataType(""));

          const paginateData = paginateDetails
            ? {
                pageNumber: paginateDetails.currentPage,
                startIndex: paginateDetails.startIndex,
                endIndex: paginateDetails.endIndex,
                rowsPerPage: paginateDetails.rowsPerPage,
                totalRecords: resulted["totalResults"],
              }
            : {
                pageNumber: 1,
                startIndex: 1,
                endIndex: 10,
                rowsPerPage: 20,
                totalRecords: resulted["totalResults"],
              };
          dispatch(
            setPage({
              data: resulted?.resources ? resulted.resources : resulted,
              paginate: paginateData,
            })
          );
        }
        dispatch(setLoading(false));
      })
      .then((result) => console.log(result))
      .catch((error) => console.log(error));
  };

export const fetchOrganizationByIdAPI =
  (id, top, skip, onLanding, paginateDetails) => async (dispatch, getState) => {
    const localState = getState().organization;
    const { isloading } = localState;

    if (isloading) {
      return;
    }
    dispatch(setLoading(true));
    //const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
    let filter = "";

    const apiURI = buildURL(
      //`${process.env.REACT_APP_API_HOST}/organizations/${id}?%24count=true`,
      `${process.env.REACT_APP_BASE_URL}/organizations/${id}?%24count=true`,
      filter,
      "",
      top,
      skip
      //sort
    );
    // const response = await axios.get(`${apiURI}`, {
    //   headers: {
    //     Authorization: `Bearer ${getJwtToken()}`,
    //   },
    //   withCredentials: false,
    // });

    // if (!response?.data) {
    //   onLanding
    //     ? dispatch(setNoDataType("onLanding"))
    //     : dispatch(setNoDataType("onSearch"));
    //   dispatch(setPageToNull());
    // } else {
    //   dispatch(setNoDataType(""));
    //   const paginateData = paginateDetails
    //     ? {
    //         pageNumber: paginateDetails.currentPage,
    //         startIndex: paginateDetails.startIndex,
    //         endIndex: paginateDetails.endIndex,
    //         rowsPerPage: paginateDetails.rowsPerPage,
    //         totalRecords: response.data["@recordsetCount"],
    //       }
    //     : {
    //         pageNumber: 1,
    //         startIndex: 1,
    //         endIndex: 10,
    //         rowsPerPage: 20,
    //         totalRecords: response.data["@recordsetCount"],
    //       };
    //   dispatch(
    //     setPage({
    //       data: response.data,
    //       paginate: paginateData,
    //     })
    //   );
    // }

    // dispatch(setLoading(false));

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${getJwtToken()}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(apiURI, requestOptions)
      .then((response) => response.json())
      .then((jsonresponse) => {
        const resulted = JSON.parse(JSON.stringify(jsonresponse));

        if (!resulted) {
          onLanding
            ? dispatch(setNoDataType("onLanding"))
            : dispatch(setNoDataType("onSearch"));
          dispatch(setPageToNull());
        } else {
          dispatch(setNoDataType(""));
          const paginateData = paginateDetails
            ? {
                pageNumber: paginateDetails.currentPage,
                startIndex: paginateDetails.startIndex,
                endIndex: paginateDetails.endIndex,
                rowsPerPage: paginateDetails.rowsPerPage,
                totalRecords: resulted["@recordsetCount"],
              }
            : {
                pageNumber: 1,
                startIndex: 1,
                endIndex: 10,
                rowsPerPage: 20,
                totalRecords: resulted["@recordsetCount"],
              };
          dispatch(
            setPage({
              data: resulted,
              paginate: paginateData,
            })
          );
        }
        dispatch(setLoading(false));
      })
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };

export const fetchOrganizationByNameAPI =
  (top, skip, onLanding, paginateDetails) => async (dispatch, getState) => {
    const localState = getState().organization;
    const { isloading, searchField } = localState;

    if (isloading) {
      return;
    }
    dispatch(setLoading(true));
    //const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
    let filter = "";
    if (searchField) {
      filter = `displayName%20eq%20${searchField}`;
    }

    const apiURI = buildURL(
      `${process.env.REACT_APP_BASE_URL}/organizations`,

      filter,
      "",
      top,
      skip
      //sort
    );
    // const response = await axios.get(`${apiURI}`, {
    //   headers: {
    //     Authorization: `Bearer ${getJwtToken()}`,
    //   },
    //   withCredentials: false,
    // });

    // if (!response?.data) {
    //   onLanding
    //     ? dispatch(setNoDataType("onLanding"))
    //     : dispatch(setNoDataType("onSearch"));
    //   dispatch(setPageToNull());
    // } else {
    //   dispatch(setNoDataType(""));
    //   const paginateData = paginateDetails
    //     ? {
    //         pageNumber: paginateDetails.currentPage,
    //         startIndex: paginateDetails.startIndex,
    //         endIndex: paginateDetails.endIndex,
    //         rowsPerPage: paginateDetails.rowsPerPage,
    //         totalRecords: response.data["@recordsetCount"],
    //       }
    //     : {
    //         pageNumber: 1,
    //         startIndex: 1,
    //         endIndex: 10,
    //         rowsPerPage: 20,
    //         totalRecords: response.data["@recordsetCount"],
    //       };
    //   dispatch(
    //     setPage({
    //       data: response.data.items,
    //       paginate: paginateData,
    //     })
    //   );
    // }
    // dispatch(setLoading(false));

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${getJwtToken()}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(apiURI, requestOptions)
      .then((response) => response.json())
      .then((jsonresponse) => {
        const resulted = JSON.parse(JSON.stringify(jsonresponse));

        if (!resulted) {
          onLanding
            ? dispatch(setNoDataType("onLanding"))
            : dispatch(setNoDataType("onSearch"));
          dispatch(setPageToNull());
        } else {
          dispatch(setNoDataType(""));
          const paginateData = paginateDetails
            ? {
                pageNumber: paginateDetails.currentPage,
                startIndex: paginateDetails.startIndex,
                endIndex: paginateDetails.endIndex,
                rowsPerPage: paginateDetails.rowsPerPage,
                totalRecords: resulted["@recordsetCount"],
              }
            : {
                pageNumber: 1,
                startIndex: 1,
                endIndex: 10,
                rowsPerPage: 20,
                totalRecords: resulted["@recordsetCount"],
              };
          dispatch(
            setPage({
              data: resulted.resources,
              paginate: paginateData,
            })
          );
        }
        dispatch(setLoading(false));
      })
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };

export const fetchGroupsUsrAPI =
  (id, top, skip, onLanding, paginateDetails) => async (dispatch, getState) => {
    const localState = getState().organization;
    const { isloading, searchField } = localState;

    if (isloading) {
      return;
    }
    dispatch(setLoading(true));

    let filter = "";
    if (searchField) {
      filter = `displayName%20eq%20${searchField}`;
    }

    const apiURI = buildURL(
      `${process.env.REACT_APP_BASE_URL}/scim/v2/groups?Filter=members.value%20eq%20${id}`,

      filter,
      "",
      top,
      skip
    );

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${getJwtToken()}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(apiURI, requestOptions)
      .then((response) => response.json())
      .then((jsonresponse) => {
        const resulted = JSON.parse(JSON.stringify(jsonresponse));

        if (!resulted) {
          onLanding
            ? dispatch(setNoDataType("onLanding"))
            : dispatch(setNoDataType("onSearch"));
          dispatch(setPageToNull());
        } else {
          dispatch(setNoDataType(""));
          const paginateData = paginateDetails
            ? {
                pageNumber: paginateDetails.currentPage,
                startIndex: paginateDetails.startIndex,
                endIndex: paginateDetails.endIndex,
                rowsPerPage: paginateDetails.rowsPerPage,
                totalRecords: resulted["@recordsetCount"],
              }
            : {
                pageNumber: 1,
                startIndex: 1,
                endIndex: 10,
                rowsPerPage: 20,
                totalRecords: resulted["@recordsetCount"],
              };
          dispatch(
            setPage({
              data: resulted.resources,
              paginate: paginateData,
              status: resulted?.code === "404" ? false : true,
            })
          );
        }
        dispatch(setLoading(false));
      })
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };

export const fetchGrantsUsrAPI =
  (userId, tenantId, top, skip, onLanding, paginateDetails) =>
  async (dispatch, getState) => {
    const localState = getState().organization;
    const { isloading, searchField } = localState;

    // if (isloading) {
    //   return;
    // }
    dispatch(setLoading(true));
    //const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
    let filter = "";
    // if (searchField) {
    //   filter = `displayName%20eq%20${searchField}`;
    // }

    const apiURI = buildURL(
      `${process.env.REACT_APP_BASE_URL}/tenants/${tenantId}/users/${userId}/grants`,

      filter,
      "",
      top,
      skip
      //sort
    );

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${getJwtToken()}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(apiURI, requestOptions)
      .then((response) => response.json())
      .then((jsonresponse) => {
        const resulted = JSON.parse(JSON.stringify(jsonresponse));
        if (!resulted) {
          onLanding
            ? dispatch(setNoDataType("onLanding"))
            : dispatch(setNoDataType("onSearch"));
          dispatch(setPageToNull());
        } else {
          dispatch(setNoDataType(""));
          const paginateData = paginateDetails
            ? {
                pageNumber: paginateDetails.currentPage,
                startIndex: paginateDetails.startIndex,
                endIndex: paginateDetails.endIndex,
                rowsPerPage: paginateDetails.rowsPerPage,
                totalRecords: resulted.items.length,
              }
            : {
                pageNumber: 1,
                startIndex: 1,
                endIndex: 10,
                rowsPerPage: 20,
                totalRecords: resulted.items.length,
              };
          dispatch(
            setPage({
              data: resulted.items,
              paginate: paginateData,
            })
          );
        }
        dispatch(setLoading(false));
      })
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };

export const fetchGrantsUsrbyOrgAPI =
  (userId, tenantId, top, skip, onLanding, paginateDetails) =>
  async (dispatch, getState) => {
    const localState = getState().organization;
    const { isloading, searchField } = localState;

    // if (isloading) {
    //   return;
    // }
    dispatch(setLoading(true));
    //const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
    let filter = "";
    // if (searchField) {
    //   filter = `displayName%20eq%20${searchField}`;
    // }

    const apiURI = buildURL(
      //`${process.env.REACT_APP_BASE_URL}/tenants/${tenantId}/users/${userId}/grants`,
      `${process.env.REACT_APP_BASE_URL}/scim/v2/groups?Filter=urn:avalara:schemas:extension:2.0:AvalaraGroup.tenant.identifier%20eq%20${tenantId}`,

      filter,
      "",
      top,
      skip
      //sort
    );

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${getJwtToken()}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(apiURI, requestOptions)
      .then((response) => response.json())
      .then((jsonresponse) => {
        const resulted = JSON.parse(JSON.stringify(jsonresponse));
        if (!resulted) {
          onLanding
            ? dispatch(setNoDataType("onLanding"))
            : dispatch(setNoDataType("onSearch"));
          dispatch(setPageToNull());
        } else {
          dispatch(setNoDataType(""));
          const paginateData = paginateDetails
            ? {
                pageNumber: paginateDetails.currentPage,
                startIndex: paginateDetails.startIndex,
                endIndex: paginateDetails.endIndex,
                rowsPerPage: paginateDetails.rowsPerPage,
                totalRecords: resulted.resources.length,
              }
            : {
                pageNumber: 1,
                startIndex: 1,
                endIndex: 10,
                rowsPerPage: 20,
                totalRecords: resulted.resources.length,
              };
          dispatch(
            setPage({
              data: resulted.resources,
              paginate: paginateData,
            })
          );
        }
        dispatch(setLoading(false));
      })
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };

export const fetchGrantsForGroups =
  (groupid, top, skip, onLanding, paginateDetails) =>
  async (dispatch, getState) => {
    let filter = "";
    const apiURI = buildURL(
      `${process.env.REACT_APP_BASE_URL}/scim/v2/groups/${groupid}/grants`,
      filter,
      "",
      top,
      skip
    );

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${getJwtToken()}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(apiURI, requestOptions)
      .then((response) => response.json())
      .then((jsonresponse) => {
        const resulted = JSON.parse(JSON.stringify(jsonresponse));
        if (!resulted) {
          onLanding
            ? dispatch(setNoDataType("onLanding"))
            : dispatch(setNoDataType("onSearch"));
          dispatch(setPageToNull());
        } else {
          dispatch(setNoDataType(""));
          const paginateData = paginateDetails
            ? {
                pageNumber: paginateDetails.currentPage,
                startIndex: paginateDetails.startIndex,
                endIndex: paginateDetails.endIndex,
                rowsPerPage: paginateDetails.rowsPerPage,
                totalRecords: resulted.resources.length,
              }
            : {
                pageNumber: 1,
                startIndex: 1,
                endIndex: 10,
                rowsPerPage: 20,
                totalRecords: resulted.resources.length,
              };
          dispatch(
            setPage({
              data: resulted.resources,
              paginate: paginateData,
            })
          );
        }
        dispatch(setLoading(false));
      })
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };

export const fetchGrantsUsrbyTenantAPI =
  (userId, tenantId, top, skip, onLanding, paginateDetails) =>
  async (dispatch, getState) => {
    const localState = getState().organization;
    const { isloading, searchField } = localState;

    // if (isloading) {
    //   return;
    // }
    dispatch(setLoading(true));
    //const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
    let filter = "";
    // if (searchField) {
    //   filter = `displayName%20eq%20${searchField}`;
    // }

    const apiURI = buildURL(
      //`${process.env.REACT_APP_BASE_URL}/tenants/${tenantId}/users/${userId}/grants`,
      `${process.env.REACT_APP_BASE_URL}/scim/v2/groups?Filter=urn:avalara:schemas:extension:2.0:AvalaraGroup.tenant.identifier%20eq%20${tenantId}`,

      filter,
      "",
      top,
      skip
      //sort
    );

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${getJwtToken()}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(apiURI, requestOptions)
      .then((response) => response.json())
      .then((jsonresponse) => {
        const resulted_groups = JSON.parse(JSON.stringify(jsonresponse));
        //if (!resulted_groups) {
        let groupid = resulted_groups?.resources ? resulted_groups?.resources[0]["id"] : "";

        let filter = "";
        const apiURI1 = buildURL(
          `${process.env.REACT_APP_BASE_URL}/scim/v2/groups/${groupid}/grants`,
          filter,
          "",
          top,
          skip
        );

        const myHeaders1 = new Headers();
        myHeaders1.append("Authorization", `Bearer ${getJwtToken()}`);

        const requestOptions1 = {
          method: "GET",
          headers: myHeaders1,
          redirect: "follow",
        };
        fetch(apiURI1, requestOptions1)
          .then((response) => response.json())
          .then((jsonresponse) => {
            const resulted = JSON.parse(JSON.stringify(jsonresponse));
            if (!resulted) {
              onLanding
                ? dispatch(setNoDataType("onLanding"))
                : dispatch(setNoDataType("onSearch"));
              dispatch(setPageToNull());
            } else {
              dispatch(setNoDataType(""));
              const paginateData = paginateDetails
                ? {
                    pageNumber: paginateDetails.currentPage,
                    startIndex: paginateDetails.startIndex,
                    endIndex: paginateDetails.endIndex,
                    rowsPerPage: paginateDetails.rowsPerPage,
                    totalRecords: resulted.resources.length,
                  }
                : {
                    pageNumber: 1,
                    startIndex: 1,
                    endIndex: 10,
                    rowsPerPage: 20,
                    totalRecords: resulted.resources.length,
                  };
              dispatch(
                setPage({
                  data: resulted.resources,
                  paginate: paginateData,
                })
              );
            }
            dispatch(setLoading(false));
          })
          .then((result) => console.log(result))
          .catch((error) => console.error(error));
      });
  };

export const fetchTenantsByIdAPI =
  (id, top, skip, onLanding, paginateDetails) => async (dispatch, getState) => {
    const localState = getState().organization;
    const { isTenantsLoading } = localState;

    if (isTenantsLoading) {
      return;
    }
    dispatch(setTenantsLoading(true));
    //const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
    let filter = "";

    const apiURI = buildURL(
      //`${process.env.REACT_APP_BASE_URL}/users/${id}/tenants?%24count=true`,
      `${process.env.REACT_APP_BASE_URL}/tenants/${id}`,

      filter,
      "",
      top,
      skip
      //sort
    );
    // const response = await axios.get(`${apiURI}`, {
    //   headers: {
    //     Authorization: `Bearer ${getJwtToken()}`,
    //   },
    //   withCredentials: false,
    // });

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${getJwtToken()}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(apiURI, requestOptions)
      .then((response) => response.json())
      .then((jsonresponse) => {
        const resulted = JSON.parse(JSON.stringify(jsonresponse));
        if (!resulted) {
          onLanding
            ? dispatch(setNoDataType("onLanding"))
            : dispatch(setNoDataType("onSearch"));
          dispatch(setPageToNull());
        } else {
          dispatch(setNoDataType(""));
          const paginateData = paginateDetails
            ? {
                pageNumber: paginateDetails.currentPage,
                startIndex: paginateDetails.startIndex,
                endIndex: paginateDetails.endIndex,
                rowsPerPage: paginateDetails.rowsPerPage,
                totalRecords: 1,
              }
            : {
                pageNumber: 1,
                startIndex: 1,
                endIndex: 10,
                rowsPerPage: 20,
                totalRecords: 1,
              };
          dispatch(
            setPage({
              data: resulted,
              paginate: paginateData,
              status: resulted?.code === "404" ? false : true,
            })
          );
        }
        dispatch(setTenantsLoading(false));
      })
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };


export const fetchUsersByOrgIdAPI =
  (id, top, skip, onLanding, paginateDetails) => async (dispatch, getState) => {
    const localState = getState().organization;
    const { isUsersLoading } = localState;

    if (isUsersLoading) {
      return;
    }
    dispatch(setUsersLoading(true));
    //const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
    let filter = "";

    const apiURI = buildURL(
      `${process.env.REACT_APP_BASE_URL}/organizations/${id}/users?%24count=true`,
      filter,
      "",
      top,
      skip
      //sort
    );
    // const response = await axios.get(`${apiURI}`, {
    //   headers: {
    //     Authorization: `Bearer ${getJwtToken()}`,
    //   },
    //   withCredentials: false,
    // });
    // if (!response?.data) {
    //   onLanding
    //     ? dispatch(setNoDataType("onLanding"))
    //     : dispatch(setNoDataType("onSearch"));
    //   dispatch(setPageToNull());
    // } else {
    //   dispatch(setNoDataType(""));
    //   const paginateData = paginateDetails
    //     ? {
    //         pageNumber: paginateDetails.currentPage,
    //         startIndex: paginateDetails.startIndex,
    //         endIndex: paginateDetails.endIndex,
    //         rowsPerPage: paginateDetails.rowsPerPage,
    //         totalRecords: response.data["@recordsetCount"],
    //       }
    //     : {
    //         pageNumber: 1,
    //         startIndex: 1,
    //         endIndex: 10,
    //         rowsPerPage: 20,
    //         totalRecords: response.data["@recordsetCount"],
    //       };
    //   dispatch(
    //     setPage({
    //       data: response.data.items,
    //       paginate: paginateData,
    //     })
    //   );
    // }

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${getJwtToken()}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(apiURI, requestOptions)
      .then((response) => response.json())
      .then((jsonresponse) => {
        const resulted = JSON.parse(JSON.stringify(jsonresponse));
        if (!resulted) {
          onLanding
            ? dispatch(setNoDataType("onLanding"))
            : dispatch(setNoDataType("onSearch"));
          dispatch(setPageToNull());
        } else {
          dispatch(setNoDataType(""));
          const paginateData = paginateDetails
            ? {
                pageNumber: paginateDetails.currentPage,
                startIndex: paginateDetails.startIndex,
                endIndex: paginateDetails.endIndex,
                rowsPerPage: paginateDetails.rowsPerPage,
                totalRecords: resulted["@recordsetCount"],
              }
            : {
                pageNumber: 1,
                startIndex: 1,
                endIndex: 10,
                rowsPerPage: 20,
                totalRecords: resulted["@recordsetCount"],
              };
          dispatch(
            setPage({
              data: resulted.items,
              paginate: paginateData,
            })
          );
        }

        dispatch(setUsersLoading(false));
      })
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };

export const fetchAppsByOrgIdAPI =
  (id, top, skip, onLanding, paginateDetails) => async (dispatch, getState) => {
    const localState = getState();
    const { isAppsLoading } = localState.organization;
    if (isAppsLoading) {
      return;
    }
    dispatch(setAppsLoading(true));
    //const sort = `${sortColumn[0]} ${sortColumn[1] ? "ASC" : "DESC"}`;
    let filter = "";

    const apiURI = buildURL(
      `${process.env.REACT_APP_BASE_URL}/organizations/${id}/apps?%24count=true`,
      filter,
      "",
      top,
      skip
      //sort
    );
    // const response = await axios.get(`${apiURI}`, {
    //   headers: {
    //     Authorization: `Bearer ${getJwtToken()}`,
    //   },
    //   withCredentials: false,
    // });
    // if (!response?.data) {
    //   onLanding
    //     ? dispatch(setNoDataType("onLanding"))
    //     : dispatch(setNoDataType("onSearch"));
    //   dispatch(setPageToNull());
    // } else {
    //   dispatch(setNoDataType(""));
    //   const paginateData = paginateDetails
    //     ? {
    //         pageNumber: paginateDetails.currentPage,
    //         startIndex: paginateDetails.startIndex,
    //         endIndex: paginateDetails.endIndex,
    //         rowsPerPage: paginateDetails.rowsPerPage,
    //         totalRecords: response.data["@recordsetCount"],
    //       }
    //     : {
    //         pageNumber: 1,
    //         startIndex: 1,
    //         endIndex: 10,
    //         rowsPerPage: 20,
    //         totalRecords: response.data["@recordsetCount"],
    //       };

    //   dispatch(
    //     setPage({
    //       data: response.data.items,
    //       paginate: paginateData,
    //     })
    //   );
    // }

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${getJwtToken()}`);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(apiURI, requestOptions)
      .then((response) => response.json())
      .then((jsonresponse) => {
        const resulted = JSON.parse(JSON.stringify(jsonresponse));
        if (!resulted) {
          onLanding
            ? dispatch(setNoDataType("onLanding"))
            : dispatch(setNoDataType("onSearch"));
          dispatch(setPageToNull());
        } else {
          dispatch(setNoDataType(""));
          const paginateData = paginateDetails
            ? {
                pageNumber: paginateDetails.currentPage,
                startIndex: paginateDetails.startIndex,
                endIndex: paginateDetails.endIndex,
                rowsPerPage: paginateDetails.rowsPerPage,
                totalRecords: resulted["@recordsetCount"],
              }
            : {
                pageNumber: 1,
                startIndex: 1,
                endIndex: 10,
                rowsPerPage: 20,
                totalRecords: resulted["@recordsetCount"],
              };

          dispatch(
            setPage({
              data: resulted.items,
              paginate: paginateData,
            })
          );
        }

        dispatch(setAppsLoading(false));
      })
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };
export default organizationSlice.reducer;
