export function getFormattedDate(dateString, USFormat) {
  const date = new Date(dateString);
  const year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : `0${month}`;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : `0${day}`;

  if (USFormat) return `${year}-${month}-${day}`;

  return `${month}/${day}/${year}`;
}

export function buildURL(baseURL, filter, include, top, skip, sortColumn) {
  // const url = new URL(
  //   process.env.REACT_APP_ENV === "local"
  //     ? `https://${baseURL}`
  //     : `https://${baseURL}`
  // );
  const url = new URL(baseURL);
  if (filter) {
    url.searchParams.append("filter", filter);
  }
  if (include && include.length) {
    url.searchParams.append("$include", include.join());
  }
  if (top) {
    url.searchParams.append("$top", top);
  } else if (top !== 0) {
    url.searchParams.append("$top", 20);
  }
  if (skip) {
    url.searchParams.append("$skip", skip);
  }
  if (baseURL.includes("/v3/") && sortColumn) {
    url.searchParams.append("$orderBy", sortColumn);
  } else if (sortColumn) {
    let [orderByParam, order] = sortColumn;
    order = order ? "asc" : "desc";
    orderByParam = `${orderByParam} ${order}`;
    url.searchParams.append("$orderBy", orderByParam);
  }
  return url.href;
}
