import React from "react";
import Overview from "../overview/Overview";

const Info = () => {
  return (
    <>
      <Overview />
      <div className="margin-top-md">
        <div className="font-semibold font-normal text-md">
          Welcome to IAM-DS User Interface!<p></p>
        </div>
        <div className="margin-bottom-lg ml-[44px]">
          This site is focused on presenting the IAM-DS data in a visual format
          to help explain and explore the data relationships. This site is
          maintained by the ECM team members as part of their IP Sprint
          activities.
        </div>
        <div className="info">
        <img src="./images/IAM-DS.png" alt="diagram" width="800"></img>
        
        <img src="./images/IAM-DS-Org.png" alt="diagram" width="800"></img>
        </div>
      </div>
    </>
  );
};

export default Info;
