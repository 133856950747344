import React, { useCallback, useEffect, useState } from "react";
import { Col, Row, Loader, InputExtended } from "@avalara/skylab-react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import { DataGrid, gridHeader } from "../../shared/grid/index.js";
import { Pagination } from "@avalara/skylab-react";
import { getFormattedDate } from "../../shared/Utils.js";


import {
  fetchOrganizationsAPI,
  selectPage,
  setTotalData,
  setPageToNull,
  selectIsLoading,
  setSearchField,
  selectSearchField,
} from "../../app/organizationSlice.js";
import Overview from "../overview/Overview.js";

export function OrganizationList() {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const loading = useSelector(selectIsLoading);
  const searchField = useSelector(selectSearchField);
  const page = useSelector(selectPage, shallowEqual);
  const totalData = useSelector(setTotalData, shallowEqual);

  useEffect(() => {
    dispatch(setSearchField(""));
    dispatch(fetchOrganizationsAPI(20, null, true));
    return () => {
      dispatch(setPageToNull());
    };
  }, [dispatch]);

  const getRowKey = useCallback((row) => {
    return row.id;
  }, []);

  const pageData = page.data;
  const paginate = page.paginate;

  const columns = [
    gridHeader(
      "id",
      "id",
      (value, row) => (
        <Link
          to={`/org/${row.id}`}
          state={{
            orgName: row.displayName,
            name:row && row?.contacts ? row?.contacts[0]?.name?.firstName +" "+row?.contacts[0]?.name?.lastName : "NA",
            email:row && row?.contacts ? row?.contacts[0]?.emails[0]?.emailId: "NA",
            createdDate:row && row?.meta ? row?.meta?.created :"NA",
          }}
        >
          {value}
        </Link>
      ),
      null,
      "",
      null,
      null,
      null,
      "id"
    ),
    gridHeader(
      "Name",
      "displayName",
      (value, row) => value,
      null,
      "",
      null,
      null,
      null,
      "displayName"
    ),

    gridHeader(
      "Created",
      "meta",
      (value) => (value ? getFormattedDate(value.created, true) : ""),
      null,
      "",
      null,
      null,
      null,
      "meta"
    )
   
    

  ];

  let table = "";
  if (loading || submitting) {
    table = (
      <div className="flex dl-flex-fill-height dl-flex-center">
        <h3>Loading ...</h3>
        <Loader id="page-loader" class="medium" aria-live="polite" loading />
      </div>
    );
  } else if (pageData?.length !== 0) {
    table = (
      <s-table-container class="margin-top-xl th-with-filter">
        <DataGrid
          columns={columns}
          rows={pageData}
          inContainer
          getKey={getRowKey}
        />
      </s-table-container>
    );
  }

  const handleSearchBoxSubmit = useCallback(
    (e) => {
      if(e?.detail)
      dispatch(setSearchField(e?.detail?.value));
      dispatch(fetchOrganizationsAPI(20, null, true, page?.paginate));
    },
    [dispatch, page.paginate]
  );
  
  const clearSearchBox = () => {
    dispatch(setSearchField(""));
    handleSearchBoxSubmit();
  };

  const handlePaginate = async (e) => {
    if (
      e.detail.currentPage !== 0 &&
      paginate.totalRecords > 0 &&
      e.detail.flag !== ""
    ) {
      setSubmitting(true);
      dispatch(
        fetchOrganizationsAPI(
          e.detail.rowsPerPage,
          e.detail.startIndex,
          false,
          e.detail
        )
      );
      setSubmitting(false);
    }
  };
  return (
    <div>
      <Overview/>
      <Row>
        <Col className="pad-bottom-none">
          <h3 className="margin-bottom-none">Organizations</h3>
          <hr />
        </Col>
        <Col span="4" className="pad-bottom-none">
          <label>Search Organizations by name</label>
          <InputExtended
            inputId="namespace"
            placeholder="Search Organizations by name"
            type="search"
            onSSearch={(e) => handleSearchBoxSubmit(e)}
            onSClear={() => clearSearchBox()}
            value={searchField}
          />
        </Col>
        <Col>
          {table}
          {pageData?.length > 0 ? (
            <Pagination
              className="margin-top-md"
              rowsPerPage={paginate.rowsPerPage}
              totalRecords={paginate.totalRecords}
              startIndex={paginate.startIndex}
              onSPaginate={(e) => {
                handlePaginate(e);
              }}
            />
          ) : null}
        </Col>
      </Row>
    </div>
  );
}
