import { createBrowserRouter } from "react-router-dom";
import Login from "./features/session/login";
import Logout from "./features/session/logout";
import Overview from "./features/overview/Overview";
import { SystemList } from "../src/features/system/systemList";
import { SystemDetails } from "./features/system/systemDetails";
import { SystemRoles } from "./features/system/systemRoles";
import { OrganizationList } from "./features/organization/organizationList";
import { UserList } from "./features/user/userList";
import { UserDetails } from "./features/user/userDetails";
import { OrganizationDetails } from "./features/organization/organizationDetails";
import TenantDetails from "./features/tenant/tenantDetails";
import Info from "./features/info/info";
import ErrorPage from "./errorhandling/Error";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Overview />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/auth/callback",
    element: <Login />,
  },
  {
    path: "/auth/logout",
    element: <Logout />,
  },
  {
    path: "/system",
    element: <SystemList />,
  },
  {
    path: "/system/roles/:id",
    element: <SystemRoles />,
  },
  {
    path: "/system/:id",
    element: <SystemDetails />,
  },
  {
    path: "/org",
    element: <OrganizationList />,
  },
  {
    path: "/org/:id",
    element: <OrganizationDetails />,
  },
  {
    path: "/usr",
    element: <UserList />,
  },
  {
    path: "/usr/:id",
    element: <UserDetails />,
  },
  {
    path: "/info",
    element: <Info />,
  },
  {
    path: "/tenantDetails/:id",
    element: <TenantDetails />,
  },
]);

export default routes;
