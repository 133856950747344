import React, { useCallback, useEffect, useState } from "react";
import { Col, Row, Loader, InputExtended } from "@avalara/skylab-react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import { DataGrid, gridHeader } from "../../shared/grid/index.js";
import { Pagination } from "@avalara/skylab-react";
import { getFormattedDate } from "../../shared/Utils.js";

import {
  fetchSystemsAPI,
  selectPage,
  setPageToNull,
  selectIsLoading,
  setSearchField,
  selectSearchField,
} from "../../app/systemSlice.js";
import Overview from "../overview/Overview.js";

export function SystemList() {
  const dispatch = useDispatch();
  const loading = useSelector(selectIsLoading);
  const searchField = useSelector(selectSearchField);
  const [submitting, setSubmitting] = useState(false);
  const page = useSelector(selectPage, shallowEqual);

  useEffect(() => {
    dispatch(fetchSystemsAPI(20, null, true));
    return () => {
      dispatch(setPageToNull());
    };
  }, [dispatch]);

  const getRowKey = useCallback((row) => {
    return row?.id;
  }, []);

  const pageData = page.data;
  const paginate = page.paginate;

  const columns = [
    gridHeader(
      "id",
      "id",
      (value, row) => (
        <Link
          to={`/system/roles/${row.id}`}
          state={{
            systemName: row.displayName,
          }}
        >
          {value}
        </Link>
      ),
      null,
      "",
      null,
      null,
      null,
      "displayName"
    ),
    gridHeader(
      "System Name",
      "displayName",
      (value, row) => <Link to={`/system/${row.id}`}>{value}</Link>,
      null,
      "",
      null,
      null,
      null,
      "displayName"
    ),
    gridHeader("Description", "description", (value) => value, null, ""),
    gridHeader("Namespace", "namespace", (value) => value, null, ""),
    gridHeader(
      "Created",
      "meta",
      (value) => (value ? getFormattedDate(value.created, true) : ""),
      null,
      "",
      null,
      null,
      null,
      "meta"
    ),
    gridHeader(
      "Modified",
      "meta",
      (value) => (value ? getFormattedDate(value.lastModified, true) : ""),
      null,
      "",
      null,
      null,
      null,
      "meta"
    ),
  ];

  let table = "";
  if (loading || submitting) {
    table = (
      <div className="flex dl-flex-fill-height dl-flex-center">
        <h3>Loading ...</h3>
        <Loader id="page-loader" class="medium" aria-live="polite" loading />
      </div>
    );
  } else if (pageData.length !== 0) {
    table = (
      <s-table-container class="margin-top-xl th-with-filter">
        <DataGrid
          columns={columns}
          rows={pageData}
          inContainer
          getKey={getRowKey}
        />
      </s-table-container>
    );
  }

  const handleSearchBoxSubmit = useCallback(
    (e) => {
      dispatch(setSearchField(e.detail.value));
      dispatch(fetchSystemsAPI(20, null, true, page?.paginate));
    },
    [dispatch, page.paginate]
  );

  const clearSearchBox = () => {
    dispatch(setSearchField(""));
    handleSearchBoxSubmit();
  };

  const handlePaginate = async (e) => {
    if (
      e.detail.currentPage !== 0 &&
      paginate.totalRecords > 0 &&
      e.detail.flag !== ""
    ) {
      setSubmitting(true);
      dispatch(
        fetchSystemsAPI(
          e.detail.rowsPerPage,
          e.detail.startIndex,
          false,
          e.detail
        )
      );
      setSubmitting(false);
    }
  };
  return (
    <div>
      <Overview/>
      <Row>
        <Col className="pad-bottom-none">
          <h3 className="margin-bottom-none">Systems</h3>
          <hr />
        </Col>
        <Col span="4" className="pad-bottom-none">
          <label>Search by namespace or display name</label>
          <InputExtended
            inputId="namespace"
            placeholder="search system by namespace"
            type="search"
            onSSearch={(e) => handleSearchBoxSubmit(e)}
            onSClear={() => clearSearchBox()}
            value={searchField}
          />
        </Col>
        <Col>
          {table}
          {pageData.length > 0 ? (
            <Pagination
              className="margin-top-md"
              rowsPerPage={paginate.rowsPerPage}
              totalRecords={paginate.totalRecords}
              startIndex={paginate.startIndex}
              onSPaginate={(e) => {
                handlePaginate(e);
              }}
            />
          ) : null}
        </Col>
      </Row>
    </div>
  );
}
