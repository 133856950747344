import React,{useEffect,useState} from "react";
import { useLocation } from "react-router-dom";
import Overview from "../overview/Overview";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { AccordionItem } from "../../components/Accordion/accordionItems.js";
import {
    fetchEntitlement,
    selectPage,
    setPageToNull,
    selectIsEntitlementsLoading,
  } from "../../app/organizationSlice.js";
import { Entitlements } from "../entitlements/entitlements";

const TenantDetails = () => {
  const location = useLocation();
  const tenantData=location?.state?.myObj;
  const dispatch = useDispatch();
  const loading = useSelector(selectIsEntitlementsLoading);
  const page = useSelector(selectPage, shallowEqual);
  const [activeAccordion, setActiveAccordion] = useState("");
  useEffect(() => {
    if (tenantData?.id) {
      console.log(tenantData?.id,"props?.idprops?.id")
      dispatch(fetchEntitlement(tenantData?.id, 20, null, true));
    }
    return () => {
      dispatch(setPageToNull());
    };
  }, [dispatch, tenantData.id]);
  return (
    <>
      <Overview />
      <h1>Tenant Details</h1>
      <div className="detail-info">
        <h2>{tenantData?.organization?.displayName}</h2>
        <div>
          <b>Name:</b> {tenantData?.displayName}
        </div>
        <div>
          <b>Created Date:</b> {tenantData?.meta?.created.slice(0,7)}
        </div>
      </div>
      <AccordionItem
          title="Entitlement"
          activeAccordion={activeAccordion}
          setActiveAccordion={setActiveAccordion}
          id="entitlement"
          iconName="catalog"
        >
      <Entitlements page={page} loading={loading} dispatch={dispatch} fetchEntitlement={fetchEntitlement}/>
        </AccordionItem>
    </>
  );
};
export default TenantDetails;
