import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { handleLogin, setCode, getJwtToken } from "../../app/sessionSlice";
import { useDispatch } from "react-redux";
import { Loader } from "@avalara/skylab-react";

const ConfigSession = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const code = searchParams.getAll("code")[0];
  const token = getJwtToken();

  useEffect(() => {
    dispatch(setCode(code));
  }, [code, dispatch]);

  useEffect(() => {
    dispatch(handleLogin());
  },[token]);

  useEffect(() => {
    if (token) {
      navigate("/org/");
    }
  }, [token, navigate]);

  const appDOM = (
    <div className="flex dl-flex-fill-height dl-flex-center">
      <h1>Loading ...</h1>
      <Loader id="page-loader" class="large" aria-live="polite" loading />
    </div>
  );
  return appDOM;
};

export default ConfigSession;
