import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Tenants } from "../tenant/tenants";
import { Users } from "../user/users";
import { Applications } from "../application/applications";
import { Groups } from "../group/groups";
import Overview from "../overview/Overview";
import { AccordionItem } from "../../components/Accordion/accordionItems";

export function OrganizationDetails() {
  const params = useParams();
  const location = useLocation();
  const { orgName, name,email,createdDate } = location?.state ? location.state : [];
  const [activeAccordion, setActiveAccordion] = useState("");

  return (
    <>
      <Overview />
      <div className="detail-info">
      <h2>{orgName}</h2>
      <div><b>Name:</b> {name}</div> 
      <div><b>Email:</b> {email}</div> 
      <div><b>Created Date:</b> {createdDate.slice(0,10)}</div> 
      </div>

      <div>
        <AccordionItem
          title="Tenants"
          activeAccordion={activeAccordion}
          setActiveAccordion={setActiveAccordion}
          id="tenants"
          iconName="catalog"
        >
          <Tenants id={params.id} from="org" />
        </AccordionItem>
        <AccordionItem
          title="Users"
          activeAccordion={activeAccordion}
          setActiveAccordion={setActiveAccordion}
          id="users"
          iconName="user"
        >
          <Users id={params.id} />
        </AccordionItem>
        <AccordionItem
          title="Apps"
          activeAccordion={activeAccordion}
          setActiveAccordion={setActiveAccordion}
          id="apps"
          iconName="app-launcher"
        >
          <Applications id={params.id} />
        </AccordionItem>
        <AccordionItem
          title="Groups"
          activeAccordion={activeAccordion}
          setActiveAccordion={setActiveAccordion}
          id="groups"
           iconName="users"
        >
          <Groups id={params.id} />
        </AccordionItem>
      </div>
    </>
  );
}