import React, { useState,useCallback, useEffect } from "react";
import { Col, Row, Loader } from "@avalara/skylab-react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { DataGrid, gridHeader } from "../../shared/grid/index.js";
import { Pagination } from "@avalara/skylab-react";
import {
  selectPage,
  selectIsGroupsLoading,
  fetchGroupsOrgAPI,
} from "../../app/organizationSlice.js";
import { fetchGroupsUsrAPI } from "../../app/userSlice.js";
export function Groups(props) {
  const dispatch = useDispatch();
  const loading = useSelector(selectIsGroupsLoading);
  const page = useSelector(selectPage, shallowEqual);

  useEffect(() => {
    if (props?.id) {
      if (props?.from === "org")
        dispatch(fetchGroupsOrgAPI(props?.id, 20, null, true));

      if (props?.from === "usr")
        dispatch(fetchGroupsUsrAPI(props?.id, 20, null, true));
    }
  }, [dispatch,props.id]);

  const getRowKey = useCallback((row) => {
    return row.id;
  }, []);

  const pageData = page?.data;
  const paginate = page?.paginate;
  let columns;
  if (pageData && pageData?.length>0) {
    columns = [
      gridHeader("Display name", "displayName", (value) => value, null, ""),
    ];
  }

  let table = "";
  if (loading) {
    table = (
      <div className="flex dl-flex-fill-height dl-flex-center">
        <h3>Loading ...</h3>
        <Loader id="page-loader" class="medium" aria-live="polite" loading />
      </div>
    );
  } else if (pageData && pageData?.length>0) {
    table = (
      <s-table-container class="margin-top-xl th-with-filter">
        <DataGrid
          columns={columns}
          rows={pageData}
          inContainer
          getKey={getRowKey}
        />
      </s-table-container>
    );
  } else {
    table = (
      <React.Fragment>
        <div className="margin-top-sm">
          <div className="font-semibold font-normal text-md">
            No groups are found
          </div>
        </div>
      </React.Fragment>
    );
  }
  return (
    <div>
      <Row>
        <Col>
          {table}
          {pageData && pageData?.length > 0 ? (
            <Pagination
              className="margin-top-md"
              rowsPerPage={paginate.rowsPerPage}
              totalRecords={paginate.totalRecords}
              startIndex={paginate.startIndex}
              onSPaginate={(e) => {}}
            />
          ) : null}
        </Col>
      </Row>
    </div>
  );
}
