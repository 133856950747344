import React from "react";

const SelectDropdown = ({ options, value, onChange, placeholder, className }) => {
    return (
      <div className={`select-dropdown ${className}`}>
        <select
          value={value}
          onChange={(e) => onChange({ label: e.target.selectedOptions[0].text, value: e.target.value })}
          className="dropdown"
        >
          <option value="" disabled>
            {placeholder}
          </option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  };

  export default SelectDropdown;