import React, { useCallback, useEffect, useState } from "react";
import { Col, Row, Loader } from "@avalara/skylab-react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { DataGrid, gridHeader } from "../../shared/grid/index.js";
import { Pagination } from "@avalara/skylab-react";
import { AccordionItem } from "../../components/Accordion/accordionItems.js";

import {
   
  selectPage,
  setPageToNull,
  selectIsAppsLoading,
} from "../../app/organizationSlice.js";
import {
  //fetchGrantsUsrAPI,
  fetchGrantsUsrbyTenantAPI
   
} from "../../app/userSlice.js";

export function TenantGrants(props) {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const loading = useSelector(selectIsAppsLoading);
  const page = useSelector(selectPage, shallowEqual);
  const [activeAccordion, setActiveAccordion] = useState("");
  useEffect(() => {
    if (props?.id) {
      dispatch(fetchGrantsUsrbyTenantAPI(props?.id,props?.tenantId, 20, null));
    }
    return () => {
      dispatch(setPageToNull());
    };
  }, [dispatch, props.id]);

  const getRowKey = useCallback((row) => {
    return row.id;
  }, []);

  const pageData = page?.data;  
  const paginate = page?.paginate;

  let columns;
  if(pageData && page?.paginate?.totalRecords && page?.paginate?.totalRecords>0)
  {

    columns = [
      gridHeader("Id", "id", (value) => value, null, "", null, null, null, "id"),
      gridHeader("Display name", "displayName", (value) => value, null, ""),
       
    ];
  }

  let table = "";
  if (loading || submitting) {
    table = (
      <div className="flex dl-flex-fill-height dl-flex-center">
        <h3>Loading ...</h3>
        <Loader id="page-loader" class="medium" aria-live="polite" loading />
      </div>
    );
  } else if (pageData && page?.paginate?.totalRecords && page?.paginate?.totalRecords>0) {
    table = (
      <s-table-container class="margin-top-xl th-with-filter">
        <DataGrid
          columns={columns}
          rows={pageData}
          inContainer
          getKey={getRowKey}
        />
      </s-table-container>
    );
  } else {
    table = (
      <React.Fragment>
        <div className="margin-top-sm">
          <div className="font-semibold font-normal text-md">
            No apps are found
          </div>
        </div>
      </React.Fragment>
    );
  }

  const handlePaginate = async (e) => {
    if (
      e.detail.currentPage !== 0 &&
      paginate?.totalRecords > 0 &&
      e.detail.flag !== ""
    ) {
      setSubmitting(true);
      dispatch(
        fetchGrantsUsrbyTenantAPI(
          e.detail.rowsPerPage,
          e.detail.startIndex,
          false,
          e.detail
        )
      );
      setSubmitting(false);
    }
  };
  return (
    <div>

        {/* <AccordionItem
          title="Tenant Level Grants"
          activeAccordion={activeAccordion}
          setActiveAccordion={setActiveAccordion}
          id="tenantgrants"
          iconName="users"
        >   */}
         <Row>
          <Col>
            {table}
            {pageData?.length > 0 ? (
              <Pagination
                className="margin-top-md"
                rowsPerPage={paginate.rowsPerPage}
                totalRecords={paginate.totalRecords}
                startIndex={paginate.startIndex}
                onSPaginate={(e) => {
                  handlePaginate(e);
                }}
              />
            ) : null}
          </Col>
         </Row>
        {/* </AccordionItem>   */}
    
      
   
    </div>
  );
}
