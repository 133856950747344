import React from "react";
import { NavLink } from "react-router-dom";

const Overview = () => {
  return (
    <div>
      <ul className="menu">
        <li>
          <NavLink
            to="/org"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            <s-icon class="pad-right-xs hydrated" name="building"></s-icon>
            Organizations
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/usr"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            <s-icon class="pad-right-xs hydrated" name="user"></s-icon>
            Users
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/info"
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            <s-icon class="pad-right-xs hydrated" name="info-circle"></s-icon>
            Info
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Overview;
